import React, {useState} from "react";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import axios from 'axios';
import $ from "jquery";
const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [message, setMessage] = useState('')

  const onSubmit = () => {

    console.log(name)
    console.log(email)
    console.log(contact)
    console.log(message)
    let data  = new FormData();
    data.append('name',name)
    data.append('email',email)
    data.append('contact',contact)
    data.append('message',message)
    

    axios({
      method: 'post',
      url: 'https://andlios.com/email.php',
      headers: { 'content-type': 'application/json' },
      data: data
  }).then(result => {
    console.log('here')
    setName('');
    setEmail('');
    setContact('');
    setMessage('');
    if(result.success){
        console.log('here')
        $('.services-cta').attr('disabled', 'true')
        $('.services-cta').attr('disabled', 'true')
    }
    
//     /* this.setState({
//         mailSent: result.data.sent
//     }) */
})
// .catch(error => this.setState({ error: error.message }));
// $('.services-cta').css('opacity', '0.5')
// event.preventDefault();
  };

  return (
    <>
        <div className="row">
          <div className="col-md-6">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control theme-light"
                  placeholder="Full name" name="name" value={name}  onChange={(e)=>{setName(e.target.value)}}
                />
              </div>
            </Fade>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="email"
                  className="form-control theme-light"
                  placeholder="Email address"name="email"
                  value={email}  onChange={(e)=>{setEmail(e.target.value)}}
                />
              </div>
            </Fade>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="number"
                  className="form-control theme-light"
                  placeholder="Phone No"name="contact" 
                  value={contact}  onChange={(e)=>{setContact(e.target.value)}}
                />
               
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  rows="4"
                  className="form-control theme-light"
                  placeholder="Type comment"name="messag"
                  value={message}  onChange={(e)=>{setMessage(e.target.value)}}

                />

              </div>
            </Fade>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <Fade bottom>
              <div className="btn-bar">
                <button className="px-btn px-btn-white" onClick={onSubmit}>Send Message</button>
              </div>


            </Fade>
          </div>
          {/* End .col-12 */}
        </div>
    </>
  );
};

export default Contact;
