import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../contact/Contact";
import Fade from "react-reveal/Fade";

Modal.setAppElement("#root");

const News = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6 m-15px-tb">
          <div className="blog-grid" onClick={toggleModalOne}>
            <div className="blog-img">
              <Fade>
                <img src={`img/blog/blog-4.jpg`} alt="blog post"></img>
              </Fade>
            </div>
            <div className="blog-info">
              <Fade bottom>
                {/* <div className="meta">29/FEB/2020 - BY ALEX</div> */}
                <h6>
                  <a>
                  Benefits of Social Media Marketing Services
                  </a>
                </h6>
              </Fade>
            </div>
          </div>
        </div>
        {/* End .col for blog-1 */}

        <div className="col-md-6 m-15px-tb">
          <div className="blog-grid" onClick={toggleModalTwo}>
            <div className="blog-img">
              <Fade>
                <img src={`img/blog/blog-2.jpg`} alt="blog post"></img>
              </Fade>
            </div>
            <div className="blog-info">
              <Fade bottom>
                {/* <div className="meta">05/JAN/2020 - BY SMITH</div> */}
                <h6>
                  <a>
                  Importance of Graphic Design
                  </a>
                </h6>
              </Fade>
            </div>
          </div>
        </div>
        {/* End .col for blog-2 */}

      </div>
      {/* End .row */}

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-4.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                    Benefits of Social Media Marketing Services:
                    </h2>
                    <div className="media">
                      {/* <div className="avatar">
                        <img src="img/testimonial/team-1.jpg" alt="thumbnail" />
                      </div> */}
                      <div className="media-body">
                        <label>By Andlios</label>
                        <span>26 FEB 2021</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                     In the beginning, when social media had only been introduced, it was thought of as a platform for young people to connect and be connected. But, as it has grown in influence and popularity, businesses realize the potential advantages of marketing via social media for their business.
                     <br></br>The benefits from Social Media Marketing for business include:

                    </p>
                    <h4>Branding:</h4>
                    <p>
Social media helps in increasing the visibility and recognition of your brand. It offers you the chance to promote your brand's image to new customers daily, instead of focusing on the same customers repeatedly by sending out promotional messages or marketing. By creating profiles on the most popular social media networks, you'll be able to increase your company's visibility and reach a wider public with Andlios professional social media marketing services. You can also ask your customers and other stakeholders to share your page's content on various social networks frequently. This will allow you to get in touch with new customers.
                    </p>
                    <h4>Easy for Search Engine Indexation:</h4>
                    <p>
                    You can boost your search engine ranking on the most popular portals by including specific keywords and phrases in Facebook and Twitter posts. You can also add hyperlinks to your website in your posts or blogs. Each time your article or blog post is shared and your followers click the links, and they'll be instantly directed to your site. The aim is to make sure that you reach as many people as possible by providing high-quality content that they can easily forward
                    </p>
                    <h4>Relationships with customers:</h4>
                    <p>
                    From the time of old, people have always wanted to conduct business or communicate with others, not institutions. People are more at ease with the human touch when purchasing any item. Social media offers your employees and you the chance to provide this personal touch and establish strong relationships with your customers. You can reply to your followers' questions and comments on a one-to one basis. If you respond promptly and show compassion when there are complaints can help increase the satisfaction of your customers. Customers should feel cared for and appreciated. They shouldn't feel they are only there to sell your goods. Online reputation management services with Andlios. 
                    </p>
                    <h4>Rates of conversion:</h4>
                    <p>
                    If you provide an individual touch to your business through social media, conversion rates immediately begin to rise. The significance of responding to all comments and reviews, even negative ones, is that it boosts the trust and loyalty of prospective customers. Increased brand recognition and visibility can also boost sales.
                    </p>
                    <h4>Marketing Costs:</h4>
                    <p>
                    If you want to market your business using traditional offline platforms, you must run many campaigns and spend lots on advertising and marketing. Social media is a cost-effective method for marketing and advertising, targeting millions of users simultaneously. You can also choose paid ads via social platforms. You can design your strategy with us and the method you use for advertising based on the outcomes of your conversion rates.
                    </p>
                    <h4>Easy for Search Engine Indexation:</h4>
                    <p>
                    You can boost your search engine ranking on the most popular portals by including specific keywords and phrases in Facebook and Twitter posts. You can also add hyperlinks to your website in your posts or blogs. Each time your article or blog post is shared and your followers click the links, and they'll be instantly directed to your site. The aim is to make sure that you reach as many people as possible by providing high-quality content that they can easily forward
                    </p>
                    <h4>Customer insights:</h4>
                    <p>
                    The most important goal when conducting marketing research is to know your customers' needs and wants. This data can assist you in launching new products and targeting the appropriate customers for those products. This information is available when you interact with your customers through social media. Understanding the demographics and the preferences and habits of prospective customers can help you figure out how best to address their questions or requests.<br></br>
One of the significant advantages that social media platforms bring to the corporate market is. When a business posts advertisement on social networking site, it will indicate the user's profile they wish to view. In the end, businesses could better connect with their market before social networks' advent. Businesses could not determine whether their advertisements were targeted and reached their intended public.

                    </p>
                    <h4>Brand Recognition:</h4>
                    <p>
                    Consumers who are currently or in the near future will be able to recognize and interact with your brand with greater ease when you have an online presence on social media. Due to the sheer amount of people using social media nowadays and, in the future, your business will have the chance to reach an increasing number of new viewers and new eyes. Social media platforms can be used to communicate your brand's story and help people aware of what your business is about.
                    </p>
                    <h4>Gives Customers Direct Access:</h4>
                    <p>
                    Companies can contact their clients directly and remain in contact with them via social media platforms. If a business has new products or promotions, or information to announce, it must be in direct contact with its customers instead of using other channels. Grow your online visibility with our captivating social media marketing services. 
                    </p>
                    <h4>Increases Website Traffic:</h4>
                    <p>
                    Thanks to social media, the options to draw visitors to your site are expanding. Every social account you have can lead to your site each post provides an opportunity to add worth and convince visitors and others why they should visit your site. The visits could result in more leads and more conversions eventually
                    </p>
                    <h4>It is Easy to Assess the Effectiveness of Your Marketing Campaigns:</h4>
                    <p>
                    A company can quickly and efficiently evaluate how its marketing strategies have worked through social media. Anyone a part of your contacts on social media websites can be counted in real-time, and you can see how many people responded to your message and liked or clicked through your message through the number of people who liked or clicked through it.
                    </p>
                    <h4>Ring Us or Schedule a Meeting with Our Social Media Experts:</h4>
                    <p>
                    It is evident that marketing on social media has many benefits, so if your company doesn't have an account on social media, it's the perfect time to start creating profiles. There are no valid reasons for not implementing social marketing through media.<br></br>
It's very cost-effective, and you'll never have anything to lose. Your competition is probably already in social networks, therefore, don't let them miss this opportunity to get a position in marketing via social media.<br></br>
For numerous online businesses, large and small, having enormous visitors to your website is a significant advantage. Because of the numerous benefits they use to use Social Media, Marketing has exploded. When attempting to grow the business, the benefits of social media marketing must be thought of. Contact us now to get full prosperous advantages of social media marketing with us.

                    </p>
                    
                  </div>
                  {/* End article content */}
                  <ul className="nav tag-cloud">
                    <li href="#">Design</li>
                    <li href="#">Web Development</li>
                    <li href="#">Social Media</li>
                    <li href="#">Web Design</li>
                    <li href="#">Digital Marketing</li>
                  </ul>
                  {/* End tag */}
                </article>
                {/* End Article */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}

      {/* Start Modal for Blog-2 */}
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-2.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                    Importance of Graphic Design
                    </h2>
                    <div className="media">
                      {/* <div className="avatar">
                        <img src="img/testimonial/team-2.jpg" alt="thumbnail" />
                      </div> */}
                      <div className="media-body">
                        <label>By Andlios</label>
                        <span>05 DEC 2021</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                    In digital technology, graphics play an essential role in boosting online visibility and helping grow sales. Technology has left an indelible impression on all facets of our consciousness, so design can't escape it’s mind-boggling developments in fashions and styles in the near future. Graphic design services are crucial, particularly when you want to draw attention to your site or social media.
                    </p>

                    <h4>What is the importance of Graphic Design for Your Business?</h4>
                    <p>
                    The things that are difficult to communicate in words can easily be communicated through the graphic. In this way, Andlios graphic design services can capture the right message to draw more audience. We make it more palatable and present your start-up's branding message freshly and innovatively. Your customers are always watching your brand and want to be able to experience new things that are new every time they see your brand's name. Therefore, to create the most effective for your business, we offer your new website's innovative designs and appealing patterns. Presenting the graphics with more refined concepts and cutting-edge technology. We will amaze you and your customers with our out-of-the-box thinking because our graphic designers have a higher level of imagination.
                    </p>
                    <h4>Why is Graphic Design Important for your Company?</h4>
                    <p>
                    The things that are difficult to communicate in words can easily be communicated through the graphic. In this way, Andlios graphic design services can capture the right message to draw more audience. We make it more palatable and present your start-up's branding message freshly and innovatively. Your customers are always watching your brand and want to be able to experience new things that are new every time they see your brand's name. Therefore, to create the most effective for your business, we offer your new website's innovative designs and appealing patterns. Presenting the graphics with more refined concepts and cutting-edge technology. We will amaze you and your customers with our out-of-the-box thinking because our graphic designers have a higher level of imagination.
                    </p>
                    <h4>Why is Graphic Design Important for your Company?</h4>
                    <p>
                    Graphics and designing have an individual opinion that could be acceptable to us but maybe a different opinion from your side. Therefore, considering your needs and concerns, we design images and graphics that satisfy your imagination.<br></br>

Graphics have much to do with your personality as a brand. Does this mean that you must present your business? Each brand has its distinct style and its style and appearance. While some could be able to convey a more regressive view of their offerings, others could be more delicate.

                    </p>
                    <h4>Your market reputation is crucial to Us</h4>
                    <p>
                    Graphics and branding are inextricably linked. A well-chosen selection of graphics communicates the message or idea you are trying to convey. Therefore, what is important to you is crucial for our graphic design services. We are a king with graphic design services, offering you the most efficient solution to your start-up business idea.
                    </p>
                    <h4>Converging and Engaging Design</h4>
                    <p>
                    The vivid and vibrant colors provide a spectacular transformation to the graphic design. Exploring the many shades and palettes of colors, Andlios Graphic designers strive for excellence. We are also aware of the importance of each color and are sure to utilize bright colors to convey a message and use subtle colors to create a gentle and gentle tone.


                    </p>
                    <h4>Featuring Brand message & Unique Selling Proposition</h4>
                    <p>
                    It's a known reality that graphics enjoy an enviable position in the digital marketing industry. From establishing the brand's identity to creating unique selling propositions, our top-notch graphic design services have helped increase sales. By studying the psychology of the brand's users on the internet, graphic designers can draw attention to the best ideas for start-ups by creating a unique and captivating web design.


                    </p>
                    <h4>Conceptualized Graphics</h4>
                    <p>
                    We use a simple process to design the logo for your brand's name. First, we schedule an initial consultation where we discuss the concepts for the start-up and the associated graphic designs. After gaining insights and ideas from your thoughts and brand's messaging, we sketch out a rough design. When you're confident that we've done an excellent job, we begin to create the final product, and we look forward to hearing your comments.
                    </p>
                    <h4>What are my payment options?</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    <h4>Focusing on the Work Culture</h4>
                    <p>
                    Graphics isn't just about placing top pictures from our image store, and it is about being curious to know your company's branding mission, culture, and vision. The layout and graphics ensure that your workplace culture is emphasized.<br></br>

From your identity? What do you want to achieve? And how do you market yourself? Beautifully designed graphics from Us will stimulate various aspects of design.
                    <h4>Connecting emotions with graphics</h4>
                    <p>
                    Being human implies being emotionally, and our graphic design assists people in connecting to the message with intense emotions. Literature and art are full of descriptions of human emotions at every level. There's no connection when the design is dull. Our designers take the emotional context into the mind before imagining any concept. This allows start-ups to use human emotion as a tool to communicate a message in the shape of visual designs to the general public to showcase their products or services effectively.
                    </p>
                    <h4>Beware of the Brand Positioning</h4>
                    <p>
                    Brand positioning is a fundamental concept in graphic design services. A brand could be expensive or high-end, so the choice for top brand images is different from the affordable options. Therefore, looking at the various aspects of branding, we pick the right graphics for your companies that are just starting.
                    </p>
                    <h4>The benefits from Graphic Design for Businesses</h4>
                    <p>
                    We are adamant about excellence and are unwilling to compromise our aesthetic standards. Therefore, we address your issues by focusing on quality. Customized Package we address your requirements by providing the highest quality in a variety of packages and on budget.<br></br>

In addition to the significance and quality of the graphics, because they are the primary feature of your site's offerings, our well-designed images with the proper branding messages will take you to the next level in the presence of your brand online. Informing your visual identity, your company can be distinguished in the crowd of 100 other brands through stunning graphic design. Converting your vision to reality, our graphic designers can sense the mood of your company and develop humorous and engaging visuals. Incorporating the latest trends and concepts, our graphic designers are experts in muted color palettes, visualization of data, flat icons and illustrations, classic serif types of fonts, Social Media slide decks, etc.

                    </p>

                    </p>
                    
                  </div>
                                    {/* End article content */}
                                    <ul className="nav tag-cloud">
                    <li href="#">Design</li>
                    <li href="#">Web Development</li>
                    <li href="#">Social Media</li>
                    <li href="#">Web Design</li>
                    <li href="#">Digital Marketing</li>
                  </ul>
                </article>
                {/* End Article */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-2 */}

     
    </>
  );
};

export default News;
