import React from "react";
import Fade from "react-reveal/Fade";
import CountUp from 'react-countup';


const Awards = () => {
  return (
    <>
      <div className="row">



<div className="col-lg-4 m-15px-tb text-center">
            
              <Fade bottom>

                <CountUp suffix="+"style={{fontSize:"60px"}}duration={1} end={4} />
                <div className="feature-content">
                     <p> Years In Business</p>
                </div>
                <div className="icon">
                  <img height="50" src={`img/award/business.png`} alt="award image" />
                </div>
              </Fade>
            
</div>
<div className="col-lg-4 m-15px-tb text-center">
            
              <Fade bottom>

                <CountUp suffix="+"style={{fontSize:"60px"}}duration={1} end={60} />
                <div className="feature-content">
                <p>Completed Projects</p>
                </div>
                <div className="icon">
                  <img height="50" src={`img/award/projects.png`} alt="award image" />
                </div>
              </Fade>
            
</div>
<div className="col-lg-4 m-15px-tb text-center">
            
              <Fade bottom>

                <CountUp suffix="+"style={{fontSize:"60px"}}duration={1} end={20} />
                <div className="feature-content">
                      <p>Countries Reached</p>
                </div>
                <div className="icon">
                  <img height="50" src={`img/award/country.png`} alt="award image" />
                </div>
              </Fade>
            
</div>



      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
